import AuthService from "@/services/AuthService";
import { nivelRotaStellantis } from "@/core/config/NivelConfig";

const userInfo = AuthService.getUsuarioAuth();
const codNivel = userInfo.codNivel;

const distribuidorRota  = {
    'stellantis': nivelRotaStellantis[codNivel] ,
}

export { distribuidorRota } ;
