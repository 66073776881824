import ApiService from "./ApiService";

export const saveLogAcesso = async (postData) => {
    const { data } = await ApiService.post(`savelog`, postData);
    return data;
};

export const enviarMensagem = async (postData) => {
    const { data } = await ApiService.post(`sms`, postData);
    return data;
};

export const getConcessionarias = async () => {
    const { data } = await ApiService.get(`concessionarialist`);
    return data;
};

export const getConcessionariaByID = async (codConcessionaria) => {
    const { data } = await ApiService.get(`concessionarias/${codConcessionaria}`);
    return data;
};

export const sendNotificacao = async (codConcessionaria, postData, dataNotificacao) => {
    const { data } = await ApiService.post(`getnovoorcamento/${codConcessionaria}/${dataNotificacao}`, postData);
    return data;
};

export const setToken = async (postData) => {
    // const { data } = await ApiService.post(`settoken`, postData);
    // return data;
};


export default {setToken, sendNotificacao, enviarMensagem, getConcessionarias, getConcessionariaByID };

