import { AgendaServico, ConsultaOS, GetOrcamentoComplementar, OrcamentoComplementarQtdTotal, OsAberta } from "@/models/AgendaModel";
import ApiService from "./ApiService";

export const getBaseAgendamento = async (codConcessionaria, diferencaDia = 0) => {
    const { data } = await ApiService.getIntegracao(`agendamento/${codConcessionaria}/${diferencaDia}`);
    return data;
};

export const getBaseAgendamentoEntrega = async (codConcessionaria, diferencaDia = 0) => {
    const { data } = await ApiService.getIntegracao(`agendamentoEntrega/${codConcessionaria}/${diferencaDia}`);
    return data;
};

export const getServicosAgendados = async (codConcessionaria, date) :  Promise<AgendaServico[]> => {
    const { data } = await ApiService.get(`agendamentoServicos/${codConcessionaria}/${date}`);
    return data;
};

export const getServicosAgendadosFiltrados = async (codConcessionaria, postData) => {
    const { data } = await ApiService.post(`agendamentoServicosFiltrado/${codConcessionaria}`, postData);
    return data;
};

export const getMecanicos = async (codConcessionaria) => {
    const { data } = await ApiService.get(`mecanicosquadro/${codConcessionaria}`);
    return data;
};

export const getHorariosOcupadosMecanicos = async (codConcessionaria, date) => {
    const { data } = await ApiService.get(`horariosOcupadosTecnicos/${codConcessionaria}/${date}`);
    return data;
};

export const addAgendamento = async (postData) => {
    const { data } = await ApiService.post(`agendamento`, postData);
    return data;
};

export const addTempoAdicionalAgendamento = async (postData) => {
    const { data } = await ApiService.post(`agendamentoTempoAdicional`, postData);
    return data;
};

export const addAgendamentoMecanico = async (postData) => {
    const { data } = await ApiService.post(`agendamentoMecanico`, postData);
    return data;
};

export const editAgendamento = async (idservico, postData) => {
    const { data } = await ApiService.put(`agendamento/${idservico}`, postData);
    return data;
};

export const editAllAgendamento = async (postData) => {
    const { data } = await ApiService.put(`editallagendamento`, postData);
    return data;
};

export const deletarAgendamento = async (postData) => {
    const { data } = await ApiService.post(`deleteAgendamento`, postData);
    return data;
};

export const iniciaServicoAgendado = async (agendamentoID) => {
    const { data } = await ApiService.post(`iniciaServicoAgendado/${agendamentoID}`, {});
    return data;
};
 
export const atualizaPercentualRealizado = async (agendamentoID, percentualRealizado) => {
    const { data } = await ApiService.post(`atualizaPercentualRealizado/${agendamentoID}/${percentualRealizado}`, {});
    return data;
};

export const paralisaServico = async (agendamentoID) => {
    const { data } = await ApiService.post(`paralisaServico/${agendamentoID}`, {});
    return data;
};

export const finalizaServicoAgendado = async (agendamentoID) => {
    const { data } = await ApiService.post(`finalizaServicoAgendado/${agendamentoID}`, {});
    return data;
};

export const cancelaServicoAgendado = async (agendamentoID) => {
    const { data } = await ApiService.post(`cancelaInicioServicoAgendado/${agendamentoID}`, {});
    return data;
};

export const entregaRealizadaServicoAgendado = async (agendamentoID) => {
    const { data } = await ApiService.post(`entregaRealizadaServicoAgendado/${agendamentoID}`, {});
    return data;
};

export const controleQualidadeRealizado = async (agendamentoID) => {
    const { data } = await ApiService.post(`qualidadeRealizadaServicoAgendado/${agendamentoID}`, {});
    return data;
};

export const cancelarControleQualidade = async (agendamentoID) => {
    const { data } = await ApiService.post(`cancelaQualidadeServicoAgendado/${agendamentoID}`, {});
    return data;
};

export const cancelaEntregaServicoAgendado = async (agendamentoID) => {
    const { data } = await ApiService.post(`cancelaEntregaServicoAgendado/${agendamentoID}`, {});
    return data;
};

export const getConsultores = async (codConcessionaria: number) => {
    const { data } = await ApiService.get(`/agendaconsultores/${codConcessionaria}`);
    return data;
};

export const consultaOsAgenda = async (payload) : Promise<ConsultaOS> => {
    const { data } = await ApiService.post(`/consultaOsAgenda`, payload);
    return data;
};

export const getServicosParalizados = async (codConcessionaria: number) : Promise<AgendaServico> =>{
    const { data } = await ApiService.get(`/servicosParalizado/${codConcessionaria}`);
    return data;
};

export const programarServicoParalizado = async (payload) => {
    const { data } = await ApiService.post(`/programadoServicoParalizado/${payload.codServico}`, payload);
    return data;
};

export const removerServicoParalizado = async (codServico: string) : Promise<string> =>{
    const { data } = await ApiService.get(`/removerServicoParalizado/${codServico}`);
    return data;
};

export const getOsAberta = async (codConcessionaria: number, day: string | number = 0) : Promise<OsAberta[]> =>{
    const { data } = await ApiService.getIntegracao(`/agenda/os-aberta/${codConcessionaria}/${day}`);
    return data;
};

export const orcamentoComplementar = async (codConcessionaria: number) : Promise<GetOrcamentoComplementar> =>{
    const { data } = await ApiService.get(`/orcamento-complementar/${codConcessionaria}`);
    return data;
};

export const orcamentoComplementarTotal = async (codConcessionaria: number) : Promise<OrcamentoComplementarQtdTotal> =>{
    const { data } = await ApiService.get(`/orcamento-complementar/total/${codConcessionaria}`);
    return data;
};
